import { createI18n } from 'vue-i18n';
import nl from '../json/i18n-nl.json';

type MessageSchema = typeof nl;

export const i18n = createI18n<[MessageSchema], 'nl'>({
    legacy: false,
    globalInjection: false,
    warnHtmlMessage: false,
    locale: 'nl',
    fallbackLocale: 'nl',
    messages: {
        nl: nl,
    },
});
