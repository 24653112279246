import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { i18n } from '@/i18n';
import * as Sentry from '@sentry/vue';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Cafe Salento';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent<any>(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        if (import.meta.env.PROD) {
            Sentry.init({
                app,
                dsn: 'https://593ff9cf821143b3a0d67248f222b9ab@o4504010042834944.ingest.sentry.io/4504011027513344',
            });
        }

        app.use(plugin).use(i18n).mount(el);

        return app;
    },
    progress: {
        color: '#B6413B',
    },
});
